import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./global.css";
// import UserSurveyFlowSelector from "./Components/Survey/UserSurveyFlowSelector";
// import SurveyContainer from "./Components/ListOfSurveys/SurveyContainer/SurveyContainer";
// import Survey from "./Components/Survey";
// import AcknowledgementPage from "./Components/Survey/AcknowledgementPage";
// import PartiallyCompleteSurveyAcknowledgement from "./Components/Survey/PartiallyCompleteSurveyAcknowlefdgement";
// import 'bootstrap/dist/css/bootstrap.css';
import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
// import RecruitementHomepage from "./Components/recruitment/RecruitementHomepage";
// import Questions from "./Components/recruitment/atoms/Questions";
import { changeNavigationColor } from "./LocalFunc";
import smyttenlogo from "./images/all/smytten_logo.svg";

const LazySurveyContainer = React.lazy(() =>
  import("./Components/ListOfSurveys/SurveyContainer/SurveyContainer")
);
const LazySurvey = React.lazy(() => import("./Components/Survey"));
// const LazyAcknowledgementPage = React.lazy(() =>
//   import("./Components/Survey/AcknowledgementPage")
// );

const LazyQuestions = React.lazy(() =>
  import("./Components/recruitment/atoms/Questions")
);
const LazyRecrutementPage = React.lazy(() =>
  import("./Components/recruitment/RecruitementHomepage")
);

function App() {
  const [searchParams] = useSearchParams();

  // localStorage.setItem("token",searchParams.get("atox"))

  if (searchParams.get("atox") !== null) {
    localStorage.setItem("token", searchParams.get("atox"));
  }

  if (searchParams.get("webview") !== null) {
    localStorage.setItem("webview", searchParams.get("webview"));
  }

  useEffect(() => {
    changeNavigationColor();
  }, []);

  return (
    <div className="App">
      <div className="desktop-version">
        <img className="smytten-logo" src={smyttenlogo} alt="" />
        <Routes>
          {/* <Route path="/tnc/:userId" element={<RecruitementHomepage/>} />
        <Route path="/questions/:userId" element={<Questions/>}/> */}
          <Route
            path="/tnc/:userId"
            element={
              <React.Suspense fallback="">
                <LazyRecrutementPage />
              </React.Suspense>
            }
          />
          <Route
            path="/questions/:userId"
            element={
              <React.Suspense>
                <LazyQuestions />
              </React.Suspense>
            }
          />

          <Route
            path="/:userId/surveys"
            element={
              <React.Suspense fallback="">
                <LazySurveyContainer />
              </React.Suspense>
            }
          />
          <Route
            path="/:userId/surveys/:surveyId"
            element={
              <React.Suspense fallback="">
                <LazySurvey />
              </React.Suspense>
            }
          />
          {/* <Route
            path="/:userId/surveys/:surveyId/ack"
            element={
              <React.Suspense fallback="">
                <LazyAcknowledgementPage />
              </React.Suspense>
            }
          /> */}
          {/* <Route path="/test" element={<PartiallyCompleteSurveyAcknowledgement />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
