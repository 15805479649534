import stronglyDisagreeGray from "./images/emojis/stronglyDisagreeGray.svg";
import agreeGray from "./images/emojis/agreeGray.svg";
import neutralGray from "./images/emojis/neutralGray.svg";
import disagreeGray from "./images/emojis/disagreeGray.svg";
import stronglyDisagreeGold from "./images/emojis/stronglyDisagreeGold.svg";
import agreeGold from "./images/emojis/agreeGold.svg";
import neutralGold from "./images/emojis/neutralGold.svg";
import disagreeGold from "./images/emojis/disAgreeGold.svg";
import stronglyAgreeGray from "./images/emojis/stronglyAgreeGray.svg";
import thumbsupGray from "./images/emojis/thumbsUpGray.svg";
import thumbsDownGray from "./images/emojis/thumbsDownGray.svg";
import starGray from "./images/emojis/starGray.svg";
import starGold from "./images/emojis/starGold.svg";
import stronglyAgreeGold from "./images/emojis/stronglyAgreeGold.svg";
import thumbsupGold from "./images/emojis/thumbsupGold.svg";
import thumbsDownGold from "./images/emojis/thumbsDownGold.svg";

export const Capitalize = (str) => {
  const arr = str.toLowerCase().split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export function checkMediaType(inputString) {
  const imageExtensions = [".jpeg", ".jpg", ".gif", ".png"];
  const videoExtensions = [".mp4", ".avi", ".mkv"];
  const audioExtensions = [".mp3", ".wav", ".ogg"];

  const lowercaseInput = inputString.toLowerCase();

  if (imageExtensions.some((ext) => lowercaseInput.endsWith(ext))) {
    return "image";
  } else if (videoExtensions.some((ext) => lowercaseInput.endsWith(ext))) {
    return "video";
  } else if (audioExtensions.some((ext) => lowercaseInput.endsWith(ext))) {
    return "audio";
  } else {
    return "text";
  }
}

export const trimText = (str, val) => {
  if (str?.length > val) {
    return `${str?.slice(0, val - 2)}...`;
  }
  return str;
};

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function emojisDisplay() {
  return {
    "😞": {
      active: stronglyDisagreeGold,
      in_active: stronglyDisagreeGray,
    },
    "😕": { active: disagreeGold, in_active: disagreeGray },
    "😐": { active: neutralGold, in_active: neutralGray },
    "😃": { active: agreeGold, in_active: agreeGray },
    "😊": { active: stronglyAgreeGold, in_active: stronglyAgreeGray },
    "🥳": { active: stronglyAgreeGold, in_active: stronglyAgreeGray },
    "👍": { active: thumbsupGold, in_active: thumbsupGray },
    "👎": { active: thumbsDownGold, in_active: thumbsDownGray },
    "⭐": { active: starGold, in_active: starGray },
  };
}

export function changeNavigationColor() {
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  var nua = navigator.userAgent.toLowerCase();
  var is_native =
    (nua.indexOf("mozilla/5.0") > -1 &&
      nua.indexOf("mozilla/5.0") > -1 &&
      nua.indexOf("version") > -1 &&
      nua.indexOf("android ") > -1) ||
    nua.indexOf("ipod") > -1 ||
    nua.indexOf("ipad") > -1 ||
    nua.indexOf("iphone") > -1;

  if (isMobile && is_native) {
    var userAgent = window.navigator.userAgent.toLowerCase(),
      ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios) {
      console.log("ios");
      window?.webkit?.messageHandlers?.backgroundColor.postMessage("#ffffff");
      // console.log('ios')
    } else {
      window?.WebView.navigationBarColor("#ffffff");
    }
  } else {
    //   alert('mobile web');
  }
}

export function checkDevice() {
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  var nua = navigator.userAgent.toLowerCase();
  var is_native =
    (nua.indexOf("mozilla/5.0") > -1 &&
      nua.indexOf("mozilla/5.0") > -1 &&
      nua.indexOf("version") > -1 &&
      nua.indexOf("android ") > -1) ||
    nua.indexOf("ipod") > -1 ||
    nua.indexOf("ipad") > -1 ||
    nua.indexOf("iphone") > -1;

  if (isMobile && is_native) {
    var userAgent = window.navigator.userAgent.toLowerCase(),
      ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios) {
      return "ios";
    } else {
      return "android";
    }
  } else {
    //   alert('mobile web');
    return "website";
  }
}
